import { ImageDescriptor } from "../../models/image";
import * as instant_print from "./instant_print";
import * as landing from "./landing";
import * as matches from "./matches";
import * as matchmake from "./matchmake";
import * as next_round from "./next_round";
import * as participants from "./participants";
import * as permissions from "./permissions";
import * as rosters from "./rosters";
import * as single_roster from "./single_roster";

const images: ImageDescriptor[] = [
  landing,
  matches,
  permissions,
  rosters,
  single_roster,
  participants,
  matchmake,
  next_round,
  instant_print,
];
export default images;
