import CommonLink from "../../components/common/CommonLink";
import { DescriptionProps } from "../../models/item";
import ItemIdentifier from "../item_ids";

const BATTLESHIP_GALACTICA_GITHUB = "https://github.com/KyleRAnderson/Battleship-Galactica";

export default function BattleshipGalactica({ itemUrlResolver }: DescriptionProps): JSX.Element {
  return (
    <p>
      Battleship Galactica was a cross between the classic game of chess and the modern game of battleship developed for
      my grade 11 programming final project. This application was my second major project (following the{" "}
      <CommonLink newTab={false} href={itemUrlResolver(ItemIdentifier.ApeOverMath)}>
        Ape over math game
      </CommonLink>
      ), and gave me the opportunity to learn the JavaFX gaming library more in depth. The project is available on its{" "}
      <CommonLink href={BATTLESHIP_GALACTICA_GITHUB}>GitHub page</CommonLink>.
    </p>
  );
}

export { BATTLESHIP_GALACTICA_GITHUB };
