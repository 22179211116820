import CommonLink from "../../components/common/CommonLink";

export default function Watorace(): JSX.Element {
  return (
    <>
      <p>
        MIT-PITT+RW is a joint collaboration between students at MIT Driverless, Pittsburgh institute of Technology,
        Rochester institute of technology and the University of Waterloo's WATonomous, with the goal of designing a
        fully autonomous Indy 500 race car as part of the international{" "}
        <CommonLink href="https://www.indyautonomouschallenge.com/">Indy Autonomous challenge</CommonLink>.
      </p>
      <p>
        As a founding member of this team, I have seen the project grow from the start. Most recently, I have been
        supporting our <CommonLink href="https://www.svlsimulator.com/">LG SVL</CommonLink> simulation and development
        infrastructure. We use the SVL simulator for training and testing our controller in a safe and accessible
        environment, making it a critical component of our work. The project involves a custom Docker development image
        launched by compose to run the controller and other vehicle software, as well as a Windows simulator host.
        Because the controller and Docker container is quite resource intensive, we additionally maintain a Proxmox
        cluster of development servers sporting Linux and Windows virtual machines. Supporting such an immense project
        comes with many responsibilities, including the provisioning and management of all compute resources in the
        cluster, optimising Docker builds and developer workflows for developers of varying knowledge sets, and
        real-time help for members with issues or special requirements that need to be met by the infrastructure. To top
        this off, the controller is primarily designed to run on a real vehicle, so I was responsible for creating
        several C++ ROS 2 nodes to perform topic conversions and mock sensor output.
      </p>
      <p>
        On top of being a primary owner for the simulation stack, I have made numerous contributions to the vehicle
        control software itself.
      </p>
      <p>
        Earlier with the team, I was also involved with setting up features such as MATLAB scripts to generate the
        racing line, Docker development environments, and pipelines to check over code as it was pushed. As the team
        matured, so did I, moving on to developing vehicle controller performance monitoring in C++, and a linear
        algebra based automated lap timer to help us quantify the improvements being made to the controller. This, along
        with countless hours spent iterating on various racing line inputs in the simulator have given me both an
        enjoyable hobby as well as experience with embedded C++ development.
      </p>
      <p>
        To top this off, when the team recruited a new co-op student for the winter 2021 term, I stepped up as a mentor
        to guide the student.
      </p>
    </>
  );
}
