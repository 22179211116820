import { Badge } from "react-bootstrap";
import styles from "./ItemTag.module.scss";

export default function ItemTag({ tag }: { tag: React.ReactNode }): JSX.Element {
  return (
    <Badge pill bg="secondary" className={`mx-1 ${styles.itemtag}`}>
      {tag}
    </Badge>
  );
}
