import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Item from "../../models/item";
import { AllTags, NonTypeTag, TypeTag } from "../../models/tag";
import { fetchItems } from "../../repositories/item_repository";
import { fetchTags } from "../../repositories/tag_repository";
import Loader from "../common/Loader";
import ItemsList from "../items_list";
import Sorter, { SorterProps } from "../sorter";
import Filters from "./Filters";

export type ItemsProps = {
  setDisplayItem: (displayItem: Readonly<Item>) => void;
  selectedTypes: ReadonlySet<TypeTag> | undefined;
  selectedTags: ReadonlySet<NonTypeTag> | undefined;
  handleTypeSelection: (newSelected: [TypeTag, boolean][]) => void;
  handleTagSelection: (newTagValues: [NonTypeTag, boolean][]) => void;
  allTags: AllTags | undefined;
  setAllTags: (tags: AllTags) => void;
  items: Readonly<Item>[] | undefined;
  setItems: (items: Readonly<Item>[]) => void;
} & SorterProps;

export default function Items({
  handleTagSelection,
  selectedTags,
  selectedTypes,
  handleTypeSelection,
  setDisplayItem,
  allTags,
  setAllTags,
  items,
  setItems,
  currentSortDirection,
  currentSortProperty,
  ...sorterProps
}: ItemsProps): JSX.Element {
  useEffect(() => {
    async function fetchTagsOnLoad() {
      setAllTags(await fetchTags());
    }
    if (allTags === undefined) {
      fetchTagsOnLoad();
    }
  }, [allTags, setAllTags]);

  useEffect(() => {
    async function fetchItemsOnLoad() {
      setItems(await fetchItems());
    }
    if (items === undefined) {
      fetchItemsOnLoad();
    }
  }, [setItems, items]);

  if (selectedTypes === undefined || selectedTags === undefined || allTags === undefined || items === undefined) {
    return <Loader />;
  }

  return (
    <Container fluid className="px-4">
      <Row className="mb-2">
        <Sorter
          {...sorterProps}
          currentSortDirection={currentSortDirection}
          currentSortProperty={currentSortProperty}
        />
      </Row>
      <Row>
        <Col md="2">
          <Filters
            allTags={allTags}
            selectedTags={selectedTags}
            selectedTypes={selectedTypes}
            updateTagSelection={handleTagSelection}
            updateTypeSelection={handleTypeSelection}
          />
        </Col>
        <Col md="10">
          <ItemsList setDisplayitem={setDisplayItem} items={items} />
        </Col>
      </Row>
    </Container>
  );
}
