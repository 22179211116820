import CommonLink from "../../components/common/CommonLink";

export default function SmartMirror(): JSX.Element {
  return (
    <>
      <p>
        The FLEX Smart Mirror is a prototype smart mirror display which allows users to view the weather, play music,
        and view their calendar schedule quickly while going about their daily routine. Central to my role on the
        project was creating a Python application which enabled the user to authenticate to the display without
        interaction.
      </p>
      <p>
        We accomplished this using the OpenCV computer vision library; the learning required for this project included
        computer vision basics, basic machine learning facial recognition techniques, compiling and installing the
        OpenCV C++ library and integrating the Python application with the electron frontend app to make it all work.
      </p>
      <p>
        Overall, we were quite pleased with the result, and created a{" "}
        <CommonLink href="https://youtu.be/cZ8H2EFwmOU">demo video</CommonLink> displaying the device.
      </p>
    </>
  );
}
