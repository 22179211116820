import { useCallback, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AllTags, getAllNonTypeTags, NonTypeTag, tagOrderings, TypeTag } from "../../models/tag";
import { TagFilter, Tags } from "../tags";
import styles from "./Filters.module.scss";

export type ItemFiltersProps = {
  allTags: AllTags;
  selectedTypes: ReadonlySet<TypeTag>;
  selectedTags: ReadonlySet<NonTypeTag>;
  updateTypeSelection: (newSelected: [TypeTag, boolean][]) => void;
  updateTagSelection: (newTagValues: [NonTypeTag, boolean][]) => void;
};

export default function ItemFilters({
  selectedTypes,
  allTags,
  updateTypeSelection,
  updateTagSelection,
  selectedTags,
}: ItemFiltersProps): JSX.Element {
  const updateAllSelections = useCallback(
    function updateAllSelections(value: boolean): void {
      function performUpdate<T>(updateFunction: (newSelected: [T, boolean][]) => void, tags: T[]): void {
        updateFunction(tags.map((tag) => [tag, value]));
      }
      performUpdate(updateTypeSelection, Object.values(allTags.type));
      performUpdate(
        updateTagSelection,
        tagOrderings.flatMap(([key]) => Object.values(allTags[key])),
      );
    },
    [updateTypeSelection, updateTagSelection, allTags],
  );

  const selectAll = useCallback(
    function selectAll(): void {
      updateAllSelections(true);
    },
    [updateAllSelections],
  );

  const clearSelection = useCallback(
    function clearSelection(): void {
      updateAllSelections(false);
    },
    [updateAllSelections],
  );

  const areAllSelected = useMemo(() => {
    return (
      getAllNonTypeTags(allTags).every(selectedTags.has.bind(selectedTags)) &&
      Object.values(allTags.type).every(selectedTypes.has.bind(selectedTypes))
    );
  }, [allTags, selectedTags, selectedTypes]);

  const areNoneSelected = useMemo(
    () => selectedTags.size <= 0 && selectedTypes.size <= 0,
    [selectedTags, selectedTypes],
  );

  const sortedTypeTags = useMemo(() => Object.values(allTags.type).sort(), [allTags]);
  const sortedOtherTags: [string, NonTypeTag[]][] = useMemo(() => {
    return tagOrderings.map(([categoryId, categoryLabel]) => {
      return [categoryLabel, Object.values(allTags[categoryId]).sort()];
    });
  }, [allTags]);

  return (
    <>
      <Row className="align-items-center row-cols-auto">
        <Col>Select</Col>
        <Col>
          <span className={`pr-0 pl-2 ${styles.select_all_buttons}`}>
            <Button disabled={areAllSelected} variant="link" onClick={selectAll} className="p-1">
              All
            </Button>
            <Button disabled={areNoneSelected} variant="link" onClick={clearSelection} className="p-1">
              None
            </Button>
          </span>
        </Col>
      </Row>
      <Tags<TypeTag>
        selectedTags={selectedTypes}
        onSelectionChange={updateTypeSelection}
        title="Category"
        tagsToDisplay={sortedTypeTags}
        children={TagFilter}
      />
      {sortedOtherTags.map(([categoryLabel, tags], i) => {
        return (
          <Tags<NonTypeTag>
            key={i}
            selectedTags={selectedTags}
            onSelectionChange={updateTagSelection}
            title={categoryLabel}
            tagsToDisplay={tags}
            children={TagFilter}
          />
        );
      })}
    </>
  );
}
