import { useCallback } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { SortDirection, SortProperty } from "../../models/item_sort";

export type SorterProps = {
  setSortProperty: (property: SortProperty) => void;
  currentSortProperty: SortProperty;
  setSortDirection: (direction: SortDirection) => void;
  currentSortDirection: SortDirection;
};

export default function Sorter({
  setSortProperty: onSetProperty,
  setSortDirection: onSetDirection,
  currentSortProperty,
  currentSortDirection,
}: SorterProps): JSX.Element {
  const handleSortPropertyChange = useCallback(
    function handleSortPropertyChange(
      event: Parameters<Exclude<React.ComponentProps<typeof Form.Select>["onChange"], undefined>>[0],
    ): void {
      onSetProperty(event.target.value as SortProperty);
    },
    [onSetProperty],
  );
  const handleSortDirectionChange = useCallback(
    function handleSortDirectionChange(
      event: Parameters<Exclude<React.ComponentProps<typeof Form.Select>["onChange"], undefined>>[0],
    ): void {
      onSetDirection(event.target.value as SortDirection);
    },
    [onSetDirection],
  );

  return (
    <Form className="row gx-3 row-cols-lg-auto align-items-center">
      <Col md="1">
        <Form.Label className="mb-0 me-2">Sort</Form.Label>
      </Col>
      <Col md="4">
        <Form.Select className="mx-2" value={currentSortProperty} onChange={handleSortPropertyChange}>
          {Object.entries(SortProperty).map(([key, value]) => {
            return <option key={key}>{value}</option>;
          })}
        </Form.Select>
      </Col>
      <Col md="4">
        <Form.Select className="mx-2" value={currentSortDirection} onChange={handleSortDirectionChange}>
          {Object.entries(SortDirection).map(([key, value]) => {
            return <option key={key}>{value}</option>;
          })}
        </Form.Select>
      </Col>
    </Form>
  );
}
