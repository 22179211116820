import styles from "./CommonLink.module.scss";

export type SvgLinkProps = Omit<React.ComponentProps<"a">, "children"> & {
  children: React.ReactNode; // In case the type of children in the <a> element ever changes.
  newTab?: boolean;
};

/** This function is used to stop the propagation of click events when a button is clicked.
 * This prevents parent handlers from handling the click event so that links don't cause
 * these handlers to fire.
 */
function buttonClickStopper(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
  event.stopPropagation();
}

export default function CommonLink({
  className: providedClasses,
  children,
  newTab = true,
  ...props
}: SvgLinkProps): JSX.Element {
  const totalClasses = `${providedClasses} ${styles.svg_link}`;
  const newTabProps = newTab ? { target: "_blank", rel: "noreferrer" } : {};
  return (
    <a onClick={buttonClickStopper} {...props} {...newTabProps} className={totalClasses}>
      {children}
    </a>
  );
}
