export default function Splunk(): JSX.Element {
  return (
    <>
      <p>
        At Splunk, I added to the capabilities of the Splunk cloud administrator, enabling this customer persona to
        perform more self-service configuration. Working on the admin configuration services (ACS) team, I delivered
        three major features to the ACS command-line interface, written in Go:
        <ul>
          <li>
            acs limits: A new subcommand which enables administrators to interact with the API that configures their
            Splunk stack’s search and performance settings in a comfortable manner using the CLI.
          </li>
          <li>
            acs setup: A new subcommand which reduces the CLI setup time from ~1 minute to ~15 seconds for users by
            aggregating the commands necessary to configure the CLI in a maintainable fashion.
          </li>
          <li>
            Automation enhancements: Enabled the usage of the ACS CLI in automation platforms like GitLab CI, GitHub
            Actions, or scripts in general, by adding a new parsable output format mode which functions globally across
            the CLI.
          </li>
        </ul>
        Each new feature was carefully designed, prototyped, and presented to the team in a design document prior to
        implementation. In addition to these three major features, I also came up with my own initiatives which were
        implemented:
        <ul>
          <li>
            Suggested creating a new endpoint to list authentication tokens, functionality which users could accomplish
            via the Splunk UI but not with the API. This enabled use cases such as automatic token revocation and token
            auditing.
          </li>
          <li>
            Automatic codebase dependency updates via integration with Renovate, an idea which I suggested during a
            retrospective.
          </li>
          <li>
            Improved code coverage visibility on merge requests, allowing for inline line-coverage markers with the code
            diff.
          </li>
          <li>Initiated a Go version upgrade for the repository, handling cases of incompatible dependencies.</li>
        </ul>
      </p>
    </>
  );
}
