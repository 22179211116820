import { Col, Row } from "react-bootstrap";
import ItemModel from "../../models/item";
import Item from "../items/ItemCard";

export type ItemsListProps = {
  items: Readonly<ItemModel>[];
  setDisplayitem: (itemToDisplay: Readonly<ItemModel>) => void;
};

/**
 * A component for displaying a list of items.
 */
export default function ItemsList(props: ItemsListProps): JSX.Element {
  return (
    <div className="album py-3 bg-light">
      <Row>
        {props.items.map((item, i) => (
          <Col key={i} md={4} className="py-3">
            <Item item={item} onView={props.setDisplayitem} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
