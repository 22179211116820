import React from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";

type LoaderProps = Partial<SpinnerProps>;

export default function Loader(props: LoaderProps): JSX.Element {
  const finalizedProps: { animation: "border" | "grow" } & Omit<LoaderProps, "animation"> = {
    animation: "border",
    role: "status",
    ...props,
  };
  return (
    <Spinner {...finalizedProps}>
      {/* See https://getbootstrap.com/docs/5.0/getting-started/accessibility/ for accessibility class. */}
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
