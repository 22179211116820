// If one day we use an API to define the tags, then these type definitions will need to change a little.
import allTags from "../data/tags";

const tagOrderings: [keyof Omit<typeof allTags, "type">, string][] = [
  ["language", "Languages"],
  ["library", "Frameworks & Libraries"],
  ["tool", "Tools"],
];

type IntermediateTag = typeof allTags["type"] &
  typeof allTags["language"] &
  typeof allTags["library"] &
  typeof allTags["tool"];

type Tag = IntermediateTag[keyof IntermediateTag];
type IntermediateTypeTag = typeof allTags["type"];
type TypeTag = IntermediateTypeTag[keyof IntermediateTypeTag];
type NonTypeTag = Exclude<Tag, TypeTag>;
type AllTags = typeof allTags;

/**
 * Gets all non type tags from the object of all tags.
 * @param allTags The object containing all tag definitions.
 * @return An array of all possible non type tags given the definition of all tags.
 */
function getAllNonTypeTags(allTags: AllTags): NonTypeTag[] {
  return tagOrderings.flatMap(([key]) => Object.values(allTags[key]));
}

export const flattenedTags: Tag[] = Object.values(allTags).flatMap((categoryTags) => Object.values(categoryTags));
export type { Tag, TypeTag, NonTypeTag, AllTags };
export { tagOrderings, getAllNonTypeTags };
