import { ImageDescriptor } from "../../models/image";
import * as edit_matches from "./edit_matches";
import * as instant_print_main from "./instant_print_main";
import * as landing from "./landing";
import * as main from "./main";
import * as manage from "./manage";
import * as matchmake from "./matchmake";
import * as matchmake_save from "./matchmake_save";
import * as single_match from "./single_match";
import * as single_student from "./single_student";
import * as student_search from "./student_search";

const images: ImageDescriptor[] = [
  main,
  landing,
  edit_matches,
  student_search,
  single_student,
  single_match,
  matchmake_save,
  matchmake,
  manage,
  instant_print_main,
];
export default images;
