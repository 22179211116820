import React from "react";
import { BoxArrowUpRight, Github, Youtube } from "react-bootstrap-icons";
import apeOverMathImages from "../assets/ape_over_math";
import arioImages from "../assets/ario";
import battleshipGalacticaImages from "../assets/battleship_galactica";
import frc2706Images from "../assets/frc_2706";
import huntConsoleImages from "../assets/hunt_console";
import lcboNextImages from "../assets/lcbo_next";
import multiplayerArcadeImages from "../assets/multiplayer_arcade";
import nymiImages from "../assets/nymi";
import reindeerHuntManagerImages from "../assets/reindeer_hunt_manager";
import smartMirrorImages from "../assets/smart_mirror";
import snowflakeImages from "../assets/snowflake";
import splunkImages from "../assets/splunk";
import trendMicroImages from "../assets/trend_micro";
import watoraceImages from "../assets/watorace";
import GitlabIcon from "../components/GitlabIcon";
import Item from "../models/item";
import ApeOverMath from "./item_descriptions/ApeOverMath";
import Ario from "./item_descriptions/Ario";
import BattleshipGalactica, { BATTLESHIP_GALACTICA_GITHUB } from "./item_descriptions/BattleshipGalactica";
import FRC2706 from "./item_descriptions/FRC2706";
import HuntConsole from "./item_descriptions/HuntConsole";
import LCBONext from "./item_descriptions/LCBONext";
import MultiplayerArcade, { MULTIPLAYER_ARCADE_GITHUB_BASE_URL } from "./item_descriptions/MultiplayerArcade";
import Nymi from "./item_descriptions/Nymi";
import ReindeerHuntManager from "./item_descriptions/ReindeerHuntManager";
import SmartMirror from "./item_descriptions/SmartMirror";
import Snowflake from "./item_descriptions/Snowflake";
import Splunk from "./item_descriptions/Splunk";
import TrendMicro from "./item_descriptions/TrendMicro";
import Watorace from "./item_descriptions/Watorace";
import ItemIdentifier from "./item_ids";
import tags from "./tags";

const githubLinkIcon = React.createElement(Github, { width: "25px", height: "25px" });
const gitlabLinkIcon = React.createElement(GitlabIcon, { width: "50px", height: "50px" });
const externalLinkIcon = React.createElement(BoxArrowUpRight, { width: "25px", height: "25px" });
const youtubeIcon = React.createElement(Youtube, { width: "25px", height: "25px" });
function setupFormatter(formatOptions: ConstructorParameters<typeof Intl.DateTimeFormat>[1]): (date: Date) => string {
  const format = new Intl.DateTimeFormat(undefined, formatOptions);
  return format.format.bind(format);
}

const monthFormatter = setupFormatter({ month: "long", year: "numeric" });

const projects: Item[] = [
  new Item(
    "Snowflake",
    "snowflake",
    "At Snowflake, I owned the development of a new feature that would help at least 20% of customers by enabling them to restart failed task workflows from the tasks that failed instead of from the very beginning.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addFeaturedTags(tags.language.java, tags.language.sql);
    item.dateRange = [
      {
        from: new Date(Date.UTC(2023, 0, 9)),
        to: new Date(Date.UTC(2023, 3, 28)),
        formatter: monthFormatter,
      },
    ];
    item.description = Snowflake;
    item.images = snowflakeImages;
  }),
  new Item(
    "Splunk",
    "splunk",
    "Joining the admin configuration services (ACS) team at Splunk, I rapidly delivered major features to the team’s command-line interface, all of which increased the CLI’s capabilities for end-users, all while initiating my own improvement to the team’s product and processes.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addFeaturedTags(tags.language.go);
    item.dateRange = [
      {
        from: new Date(Date.UTC(2022, 4, 9)),
        to: new Date(Date.UTC(2022, 7, 19)),
        formatter: monthFormatter,
      },
    ];
    item.description = Splunk;
    item.images = splunkImages;
  }),
  new Item(
    "Trend Micro",
    "trend-micro",
    "As a software developer with Trend Micro, I've had a diverse experience across both platform and product development teams, both involving a lot of self-lead initiatives as well as creating cloud-native solutions with a wide variety of services, including AWS, Serverless, Jenkins, and Packer.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addTags(
      tags.language.python,
      tags.language.cpp,
      tags.language.java,
      tags.library.serverless,
      tags.tool.jenkins,
      tags.tool.docker,
      tags.language.bash,
      tags.tool.packer,
      tags.tool.aws,
    );
    item.addFeaturedTags(tags.tool.aws, tags.language.cpp, tags.library.serverless, tags.language.python);
    item.dateRange = [
      {
        from: new Date(Date.UTC(2021, 8, 7)),
        to: new Date(Date.UTC(2021, 11, 17)),
        formatter: monthFormatter,
      },
      {
        from: new Date(Date.UTC(2020, 4, 4)),
        to: new Date(Date.UTC(2020, 7, 28)),
        formatter: monthFormatter,
      },
    ];
    item.description = TrendMicro;
    item.images = trendMicroImages;
  }),
  new Item(
    "MIT Driverless, WATonomous",
    ItemIdentifier.Watorace,
    "Joining this autonomous race car team at its inception, I have had the pleasure of working on many different aspects of the vehicle platform, from providing the simulation infrastructure and support needed for verification to implementing core vehicle platform features.",
  ).tap((item) => {
    item.addTypes(tags.type.extracurricular);
    item.addFeaturedTags(tags.language.cpp, tags.tool.docker);
    item.addTags(
      tags.language.cpp,
      tags.tool.docker,
      tags.language.python,
      tags.language.matlab,
      tags.library.ros2,
      tags.language.bash,
      tags.tool.git,
    );
    item.links.push({
      url: "https://watorace.ca/",
      label: externalLinkIcon,
    });
    item.dateRange = {
      from: new Date(Date.UTC(2020, 4)),
      to: null,
      formatter: monthFormatter,
    };
    item.description = Watorace;
    item.images = watoraceImages;
  }),
  new Item(
    "Hunt Console",
    ItemIdentifier.HuntConsole,
    "A service backed by Rails with a React frontend which manages an annual contest between 1700 students at my high school.",
  ).tap((item) => {
    item.addTags(
      tags.library.rails,
      tags.library.react,
      tags.language.ruby,
      tags.tool.git,
      tags.tool.google_cloud,
      tags.tool.heroku,
      tags.language.html,
      tags.language.scss,
      tags.language.sql,
      tags.language.typescript,
      tags.library.bootstrap,
      tags.language.java,
    );
    item.addTypes(tags.type.project);
    item.addFeaturedTags(tags.library.rails, tags.library.react);
    item.links.push({
      url: "https://gitlab.com/hunt-console",
      label: gitlabLinkIcon,
    });
    item.description = HuntConsole;
    item.images = huntConsoleImages;
    item.dateRange = { from: new Date(Date.UTC(2020, 4, 1)), to: null, formatter: monthFormatter };
  }),
  new Item(
    "LCBO|Next",
    "lcbo-next",
    "Working with a small team of other interns involved diving into a fast-paced development environment and managing multiple web app stacks, including React, Angular and Express, all deployed using Azure.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addTags(
      tags.language.typescript,
      tags.language.javascript,
      tags.language.sql,
      tags.language.css,
      tags.language.html,
      tags.language.scss,
      tags.library.react,
      tags.library.angular,
      tags.library.express,
      tags.library.ionic,
      tags.tool.postgresql,
      tags.tool.azure,
      tags.tool.elasticsearch,
    );
    item.addFeaturedTags();
    item.dateRange = {
      from: new Date(Date.UTC(2021, 0, 4)),
      to: new Date(Date.UTC(2021, 3, 23)),
      formatter: monthFormatter,
    };
    item.description = LCBONext;
    item.images = lcboNextImages;
    item.links.push({
      url: "https://www.lcbonext.com/",
      label: externalLinkIcon,
    });
  }),
  new Item(
    "Ario",
    ItemIdentifier.Ario,
    "I started at Ario as an automation testing developer, and moved my way up to becoming a full stack developer for their Rails and Angular stacks, all while still in high school.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addFeaturedTags(tags.language.ruby, tags.language.typescript, tags.library.rails, tags.library.angular);
    item.addTags(
      tags.language.ruby,
      tags.language.typescript,
      tags.language.javascript,
      tags.language.python,
      tags.language.css,
      tags.language.html,
      tags.library.angular,
      tags.library.rails,
      tags.tool.git,
      tags.library.rspec,
      tags.tool.heroku,
      tags.tool.postgresql,
    );
    item.dateRange = {
      from: new Date(Date.UTC(2018, 6, 3)),
      to: new Date(Date.UTC(2019, 7, 23)),
      formatter: monthFormatter,
    };
    item.links.push({
      label: externalLinkIcon,
      url: "https://www.arioplatform.com/",
    });
    item.description = Ario;
    item.images = arioImages;
  }),
  new Item(
    "FRC 2706",
    ItemIdentifier.FRC2706,
    "A FIRST robotics team tasked with designing robots to compete in an annual challenge. I played several key roles, including as a member of the software sub team and the drive team",
  ).tap((item) => {
    item.addFeaturedTags(tags.language.java);
    item.addTags(tags.language.javascript, tags.library.junit, tags.library.jmock);
    item.addTypes(tags.type.extracurricular);
    item.links.push({
      url: "https://github.com/FRC2706",
      label: githubLinkIcon,
    });
    item.dateRange = {
      from: new Date(Date.UTC(2016, 9, 1)),
      to: new Date(Date.UTC(2020, 7, 1)),
      formatter: monthFormatter,
    };
    item.description = FRC2706;
    item.images = frc2706Images;
  }),
  new Item(
    "FLEX Smart Mirror",
    "smart-mirror",
    "A smart mirror, complete with OpenCV-based facial recognition authentication. A real hardware prototype was constructed using a Raspberry Pi.",
  ).tap((item) => {
    item.addTypes(tags.type.project, tags.type.academic);
    item.addFeaturedTags(tags.language.python, tags.library.opencv);
    item.addTags(tags.language.python, tags.language.javascript, tags.library.opencv);
    item.links.push(
      {
        label: youtubeIcon,
        url: "https://youtu.be/cZ8H2EFwmOU",
      },
      {
        label: githubLinkIcon,
        url: "https://github.com/KyleRAnderson/FLEX-Facial-Recognition",
      },
    );
    item.description = SmartMirror;
    item.dateRange = {
      from: new Date(Date.UTC(2019, 8, 1)),
      to: new Date(Date.UTC(2019, 11, 31)),
      formatter: monthFormatter,
    };
    item.images = smartMirrorImages;
  }),
  new Item(
    "Reindeer Hunt Manager",
    ItemIdentifier.ReindeerHuntManager,
    "The predecessor to the Hunt Console, this Windows desktop application has managed the annual Reindeer Hunt competition between 1700 students at my high school for over three years.",
  ).tap((item) => {
    item.addTypes(tags.type.project);
    item.addTags(tags.language.csharp, tags.tool.git, tags.library.wpf, tags.library.dot_net);
    item.addFeaturedTags(tags.language.csharp, tags.library.wpf, tags.library.dot_net);
    item.links.push({ url: "https://gitlab.com/kyle_anderson/Reindeer-Hunter", label: gitlabLinkIcon });
    item.description = ReindeerHuntManager;
    item.images = reindeerHuntManagerImages;
  }),
  new Item(
    "Multiplayer Arcade",
    ItemIdentifier.MultiplayerArcade,
    "A peer-to-peer multiplayer implementation of the classic game of pong!",
  ).tap((item) => {
    item.links.push({ url: MULTIPLAYER_ARCADE_GITHUB_BASE_URL, label: githubLinkIcon });
    item.addTypes(tags.type.project);
    item.addTags(tags.library.javafx, tags.language.java, tags.tool.git);
    item.addFeaturedTags(tags.language.java, tags.library.javafx);
    item.description = MultiplayerArcade;
    item.images = multiplayerArcadeImages;
    // No date is included on this project because a date wouldn't really be relevant.
  }),
  new Item(
    "Nymi",
    ItemIdentifier.Nymi,
    "As a quality assurance tester for Nymi’s wearable authentication devices, I ran weekly tests and relayed debugging information to the team to ensure high quality product.",
  ).tap((item) => {
    item.addTypes(tags.type.internship);
    item.addFeaturedTags(tags.language.python, tags.tool.jira);
    item.addTags(tags.language.python, tags.tool.jira);
    item.links.push({
      url: "https://www.nymi.com/",
      label: externalLinkIcon,
    });
    item.dateRange = {
      from: new Date(Date.UTC(2016, 9, 7)),
      to: new Date(Date.UTC(2018, 4, 3)),
      formatter: monthFormatter,
    };
    item.description = Nymi;
    item.images = nymiImages;
  }),
  new Item(
    "Battleship Galactica",
    ItemIdentifier.BattleshipGalactica,
    "A two-player local multiplayer of a variant on the classic game of Battleship.",
  ).tap((item) => {
    item.addTypes(tags.type.project, tags.type.academic);
    item.addFeaturedTags(tags.language.java, tags.library.javafx);
    item.links.push({
      url: BATTLESHIP_GALACTICA_GITHUB,
      label: githubLinkIcon,
    });
    item.description = BattleshipGalactica;
    item.images = battleshipGalacticaImages;
  }),
  new Item(
    "Ape Over Math",
    ItemIdentifier.ApeOverMath,
    "A game where players must answer math questions in order to be able to jump over oncoming barrels.",
  ).tap((item) => {
    item.addTypes(tags.type.project, tags.type.academic);
    item.addFeaturedTags(tags.language.python, tags.library.pygame, tags.library.tkinter);
    item.links.push({
      url: "https://github.com/KyleRAnderson/Ape-over-Math-project",
      label: githubLinkIcon,
    });
    item.description = ApeOverMath;
    item.images = apeOverMathImages;
  }),
];

export default projects;
