import { useEffect, useRef } from "react";

/* Similar to the implementation described in https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state,
though I only run the useEffect when the value changes. */
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
