import { Tag } from "../../models/tag";
import ItemTag from "./ItemTag";

export default function ItemTags({ tags }: { tags: readonly Tag[] }): JSX.Element {
  return (
    <div className="gy-1">
      {tags.map((tag) => (
        <ItemTag key={tag} tag={tag} />
      ))}
    </div>
  );
}
