import { useMemo } from "react";
import { ImageDescriptor, ImageType, imageTypeToMime, MIME_TYPE_PRIORITY } from "../../models/image";

export type ImageSelectorProps = {
  availableFormats: Readonly<ImageDescriptor>;
  /** A custom image element to be used. */
  imageElement?: (src: string | undefined, altText: string) => React.ReactNode;
};

export default function ImageSelector({
  availableFormats,
  imageElement: imageElementSupplier,
}: ImageSelectorProps): JSX.Element {
  const [availableFormatsPriority, defaultImage]: [ImageType[], React.ReactNode] = useMemo(() => {
    // The formats available for the given image, in prioritized order.
    const formatsPriority = MIME_TYPE_PRIORITY.filter((imageType) => typeof availableFormats[imageType] === "string");
    const lastAvailableIndex = formatsPriority.length - 1;
    const mostCompatibleFormatType = formatsPriority[lastAvailableIndex];

    // The fallback image to render (should be more compatible with browsers).
    let imageElement: React.ReactNode;
    if (imageElementSupplier) {
      imageElement = imageElementSupplier(availableFormats[mostCompatibleFormatType], availableFormats.altText);
    } else {
      imageElement = <img src={availableFormats[mostCompatibleFormatType]} alt={availableFormats.altText} />;
    }
    return [formatsPriority.slice(0, lastAvailableIndex), imageElement];
  }, [imageElementSupplier, availableFormats]);

  return (
    <picture>
      {/* Render the available images (except for the last one) in prioritized order. */}
      {availableFormatsPriority.map((imageType) => {
        /* The image type can be used as the key 
          since there should only be one source for each image type. */
        return <source key={imageType} type={imageTypeToMime(imageType)} srcSet={availableFormats[imageType]} />;
      })}
      {defaultImage}
    </picture>
  );
}
