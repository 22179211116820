import { useCallback, useMemo, useState } from "react";
import { Col, Container, Modal, Pagination, Row } from "react-bootstrap";
import ItemModel from "../../../models/item";
import { itemPath } from "../../../routes";
import Loader from "../../common/Loader";
import { ImageCarousel } from "../../images";
import ItemTags from "../ItemTags";

/** The key bound to proceeding to the next item. */
const NEXT_KEY = "ArrowRight";
/** The key bound to navigating to the previous item. */
const PREVIOUS_KEY = "ArrowLeft";

export type ItemDisplayProps = {
  item?: Readonly<ItemModel>;
  /** Function invoked when the ProjectDisplay needs to hide the project. */
  hideItem: () => void;
  /** A function to execute when the previous item should be shown. */
  showPreviousItem?: () => void;
  /** A function to execute when the next item should be shown. */
  showNextItem?: () => void;
};

export default function ItemDisplay({
  item,
  hideItem: hideProject,
  showNextItem,
  showPreviousItem,
}: ItemDisplayProps): JSX.Element {
  /** True when the modal is performing its hide animation but has not yet set the project to undefined. */
  const [isTransitioningOut, setIsTransitioningOut] = useState<boolean>(false);

  const hideHandler = useCallback(() => {
    setIsTransitioningOut(true);
  }, [setIsTransitioningOut]);

  const handleOnExited = useCallback(() => {
    setIsTransitioningOut(false);
    hideProject();
  }, [setIsTransitioningOut, hideProject]);

  let modalContent: React.ReactNode;
  let imageCarousel: React.ReactNode = null;
  // Could do this all in-line with the stuff below, but that's just messy.
  if (!item) {
    modalContent = (
      <Row className="justify-content-center">
        <Loader className="mx-auto" />
      </Row>
    );
  } else {
    if (item.images && item.images.length >= 2) {
      imageCarousel = (
        <Row className="justify-content-center">
          <ImageCarousel images={item?.images} />
        </Row>
      );
    } else if (!item.description) {
      console.warn("Missing description for item: ", item);
      modalContent = (
        <Row className="justify-content-start">
          <p>{item.summary}</p>
        </Row>
      );
    }
    if (item.description) {
      if (typeof item.description === "function") {
        modalContent = item.description({ itemUrlResolver: itemPath });
      } else {
        modalContent = item.description;
      }
      /* We make sure to wrap the content in a Col, otherwise we get some undesirable layouts.
      See the warning for this on https://getbootstrap.com/docs/5.1/layout/columns/. */
      modalContent = (
        <Row>
          <Col>{modalContent}</Col>
        </Row>
      );
    }
  }

  const sortedTags = useMemo(() => item?.tags && Array.from(item.tags).sort(), [item]);
  let footer: React.ReactNode = null;
  if (typeof showPreviousItem !== "undefined" || typeof showNextItem !== "undefined") {
    footer = (
      <Modal.Footer>
        <Pagination>
          <Pagination.Prev onClick={showPreviousItem} disabled={!showPreviousItem} />
          <Pagination.Next onClick={showNextItem} disabled={!showNextItem} />
        </Pagination>
      </Modal.Footer>
    );
  }

  const handleKeyDown = useCallback(
    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
      switch (event.key) {
        case PREVIOUS_KEY:
          showPreviousItem?.();
          break;
        case NEXT_KEY:
          showNextItem?.();
          break;
        default:
          break;
      }
    },
    [showPreviousItem, showNextItem],
  );

  return (
    <div onKeyDown={handleKeyDown}>
      <Modal centered size="xl" show={!isTransitioningOut} onHide={hideHandler} onExited={handleOnExited}>
        <Modal.Header closeButton>
          <Modal.Title>{item?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {sortedTags && (
              <Row className="justify-content-start mb-3">
                <ItemTags tags={sortedTags} />
              </Row>
            )}
            {imageCarousel}
            <div className="mt-3">{modalContent}</div>
          </Container>
        </Modal.Body>
        {footer}
      </Modal>
    </div>
  );
}
