import CommonLink from "../../components/common/CommonLink";

export default function FRC2706(): JSX.Element {
  return (
    <>
      <p>
        FRC 2706 is a{" "}
        <CommonLink href="https://www.firstinspires.org/robotics/frc">FIRST Robotics Competition Team</CommonLink> that
        designs and realizes robots that take part in an annual competition to outperform other teams’ robots at a
        specified series of tasks. I joined the team and began working with the software sub-team on new driver
        signalling devices which allow the robot to convey its status through LED display patterns. I also took the
        initiative to become a member of the drive team, responsible for operating the robot in the playing field.
      </p>
      <p>
        In later years, while still a member of the drive team, I put together a driver dashboard Windows application to
        assist with operating the robot.
      </p>
      <p>
        In my final year, as a senior member of the team, I had the chance to take even larger ownership over the
        software codebase, writing out many of the subsystems responsible for robot movements and setting up an
        automated testing pipeline.
      </p>
      <p>
        During my time on the team, we qualified for the Ontario provincial championships every year, and even went as
        far as the world championships. On top of all this experience, I also completed over 65 hours of community
        outreach, including software workshops to teach programming to a wider audience.
      </p>
    </>
  );
}
