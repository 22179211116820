const IMAGE_TYPES = ["apng", "avif", "gif", "jpeg", "png", "svg+xml", "webp"] as const;

type ImageType = typeof IMAGE_TYPES[number];
type ImageMimeType = `image/${ImageType}`;

/** The priority of image types.
 * Images should be displayed in the first format in this list in which
 * the image is available and the browser supports.
 */
const MIME_TYPE_PRIORITY: ImageType[] = ["avif", "webp", "gif", "png"];

type ImageDescriptor = {
  [key in ImageType]?: string;
} & { altText: string }; // A png image is required, since this should work on all platforms

function imageTypeToMime(imageType: ImageType): ImageMimeType {
  return `image/${imageType}` as ImageMimeType;
}

export type { ImageMimeType, ImageType, ImageDescriptor };
export { MIME_TYPE_PRIORITY, imageTypeToMime };
