import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { Items, SingleItem } from "../../components/items";
import usePrevious from "../../lib/usePrevious";
import ItemModel from "../../models/item";
import { SortDirection, SortProperty } from "../../models/item_sort";
import { AllTags, getAllNonTypeTags, NonTypeTag, TypeTag } from "../../models/tag";
import { itemPath, itemsPath } from "../../routes";
import sortItems from "./item_sort";

function useSelectionHandler<T>(
  currentSet: Set<T> | undefined,
  setter: (newSet: Set<T>) => void,
): (newSelection: [T, boolean][] | T[]) => void {
  return useCallback(
    (newSelection: [T, boolean][] | T[]): void => {
      let newSelectedSet: Set<T>;
      if (Array.isArray(newSelection) && Array.isArray(newSelection[0]) && typeof newSelection[0][1] === "boolean") {
        newSelectedSet = new Set(Array.from(currentSet || []));
        (newSelection as [T, boolean][]).forEach(([selectedItem, isSelected]) => {
          isSelected ? newSelectedSet.add(selectedItem) : newSelectedSet.delete(selectedItem);
        });
      } else {
        newSelectedSet = new Set(newSelection as T[]);
      }
      setter(newSelectedSet);
    },
    [currentSet, setter],
  );
}

/**
 * Container component for all items being displayed (projects, internships, etc).
 */
export default function ItemsContainer(): JSX.Element {
  const [items, setItems] = useState<Readonly<ItemModel>[]>();
  const [allTags, setAllTags] = useState<AllTags | undefined>();
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [sortProperty, setSortProperty] = useState<SortProperty>(SortProperty.Featured);
  const previousTags = usePrevious(allTags);
  // All tags are selected by default.
  const [selectedTags, setSelectedTags] = useState<Set<NonTypeTag>>();
  const [selectedTypes, setSelectedTypes] = useState<Set<TypeTag>>();
  const [currentDisplayItem, setDisplayItem] = useState<Readonly<ItemModel> | undefined | null>();

  useEffect(
    function handleAllTagsChange() {
      if (allTags !== undefined && previousTags === undefined) {
        setSelectedTags(new Set(getAllNonTypeTags(allTags)));
        setSelectedTypes(new Set(Object.values(allTags.type)));
      }
    },
    [allTags, previousTags],
  );

  const handleTagSelection = useSelectionHandler<NonTypeTag>(selectedTags, setSelectedTags);
  const handleTypeSelection = useSelectionHandler<TypeTag>(selectedTypes, setSelectedTypes);

  const history = useHistory();

  const handleSelectedItemSet = useCallback(
    function handleSelectedItemSet(newSelectedItem: Readonly<ItemModel> | undefined | null): void {
      if (newSelectedItem) {
        history.push(itemPath(newSelectedItem));
      } else {
        history.push(itemsPath);
      }
      setDisplayItem(newSelectedItem);
    },
    [history],
  );

  const isItemApplicable = useCallback(
    function isItemApplicable(item: Readonly<ItemModel>): boolean {
      return (
        selectedTypes !== undefined &&
        selectedTags !== undefined &&
        Array.from(item.types).some(selectedTypes.has.bind(selectedTypes)) &&
        Array.from(item.tags).some(selectedTags.has.bind(selectedTags))
      );
    },
    [selectedTypes, selectedTags],
  );

  const filteredItems = useMemo(() => {
    return items?.filter(isItemApplicable);
  }, [items, isItemApplicable]);

  const sortedItems = useMemo(
    () => sortItems(filteredItems, sortProperty, sortDirection),
    [filteredItems, sortProperty, sortDirection],
  );

  const currentItemIndex = useMemo(() => {
    if (currentDisplayItem) {
      return sortedItems?.findIndex((item) => item === currentDisplayItem);
    }
    return undefined;
  }, [currentDisplayItem, sortedItems]);

  function useItemNavigator(currentItemIndex: number | undefined, nextIndexSupplier: (currentIndex: number) => number) {
    return useMemo(() => {
      if (currentItemIndex !== undefined) {
        return function () {
          handleSelectedItemSet(sortedItems?.[nextIndexSupplier(currentItemIndex)]);
        };
      }
      return undefined;
    }, [currentItemIndex, nextIndexSupplier]);
  }

  const showNext = useItemNavigator(
    currentItemIndex,
    useCallback((currentIndex) => (sortedItems ? (currentIndex + 1) % sortedItems.length : 0), [sortedItems]),
  );
  const showPrevious = useItemNavigator(
    currentItemIndex,
    useCallback(
      (currentIndex) => (sortedItems ? (currentIndex - 1 + sortedItems.length) % sortedItems.length : 0),
      [sortedItems],
    ),
  );

  return (
    <>
      <Items
        allTags={allTags}
        handleTagSelection={handleTagSelection}
        handleTypeSelection={handleTypeSelection}
        selectedTags={selectedTags}
        selectedTypes={selectedTypes}
        setAllTags={setAllTags}
        setDisplayItem={handleSelectedItemSet}
        items={sortedItems}
        setItems={setItems}
        currentSortDirection={sortDirection}
        currentSortProperty={sortProperty}
        setSortDirection={setSortDirection}
        setSortProperty={setSortProperty}
      />
      <Route path={itemPath()}>
        <SingleItem
          setDisplayItem={handleSelectedItemSet}
          itemToDisplay={currentDisplayItem}
          showNextItem={showNext}
          showPreviousItem={showPrevious}
        />
      </Route>
    </>
  );
}
