import { useCallback } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import ItemModel from "../../models/item";
import { ImageSelector } from "../images";
import DateRanges from "./DateRanges";
import ItemTags from "./FeaturedItemTags";
import styles from "./ItemCard.module.scss";
import ItemLinks from "./single/ItemLinks";

const ENTER_KEY = "Enter";

export type ItemCardProps = {
  item: Readonly<ItemModel>;
  /** A function to be called when this item is requested to be viewed. */
  onView: (item: Readonly<ItemModel>) => void;
};

export default function ItemCard({ item, onView }: ItemCardProps): JSX.Element {
  const handleOnView = useCallback(() => {
    onView(item);
  }, [item, onView]);

  let image = null;
  if (item.images !== undefined) {
    image = (
      <ImageSelector
        availableFormats={item.images[0]}
        imageElement={(src, alt) => <Card.Img variant="top" alt={alt} src={src} />}
      />
    );
  }

  const handleKeyup = useCallback(
    function handleKeyup(event: React.KeyboardEvent<HTMLDivElement>): void {
      if (event.key === ENTER_KEY) {
        handleOnView();
      }
    },
    [handleOnView],
  );

  return (
    <Card className={`mb-4 shadow-sm h-100 ${styles.projectCard}`} onKeyUp={handleKeyup} onClick={handleOnView}>
      {image}
      <Card.Header>
        <Card.Title>{item.name}</Card.Title>
        <DateRanges ranges={item.dateRange} />
        <ItemTags item={item} />
      </Card.Header>
      <Card.Body>
        <Card.Text>{item.summary}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <Row className="px-2 justify-content-start align-items-center">
          <Col className="text-start px-0">
            <ItemLinks item={item} />
          </Col>
          <Col md="3" className="ms-auto">
            {/* onClick behaviour of this button should call the onClick of the parent.
          The button however will be tabbable. */}
            <Button variant="link" className={`ms-auto ${styles.chevron_button}`}>
              <ChevronRight />
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}
