import styles from "./GitlabIcon.module.scss";

function GitlabIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={styles.gitlab_icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 586 559"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M461.5 298.4l-18-55.6v0l-35.8-110.3c-2-5.8-7.4-9.7-13.5-9.7-6.2 0-11.4 3.9-13.4 9.7l-34.1 104.7H239.4l-34.1-104.7c-1.9-5.9-7.2-9.7-13.4-9.7h-.1c-6.1 0-11.5 3.9-13.5 9.8l-35.7 110.2v0l-18.1 55.6c-2.7 8.4.2 17.5 7.4 22.7l156.4 113.6h.1c.1 0 .1.1.2.1-.1 0-.1-.1-.2-.1.2.2.5.3.8.5 0 0 .1 0 .1.1H289.6c.1.1.3.1.4.2.1.1.3.1.4.2H290.7c.2.1.4.1.6.1.1 0 .1 0 .2.1H291.8c.4.1.7.1 1.1.1.4 0 .8 0 1.1-.1H294.3c.1 0 .2 0 .2-.1.2 0 .4-.1.6-.1H295.4c.1 0 .3-.1.4-.2.1-.1.3-.1.4-.2H296.5s.1 0 .1-.1c.3-.1.5-.3.7-.5h.2l156.4-113.6c7.4-5.2 10.3-14.4 7.6-22.7zm-67.3-155.6l30.7 94.5h-61.4l30.7-94.5zm25.3 110.4l-12.5 16-92.4 118.4 43.7-134.3h61.2zM285.4 430.7v0zm-14-43.1L166.6 253.2h61.2l43.6 134.4zm-79.5-244.8l30.7 94.5h-61.4l30.7-94.5zm-50.6 165.3c-1.5-1.1-2.1-3-1.6-4.8l13.5-41.3 98.7 126.5-110.6-80.4zm146.8 126.4l-.1-.1c-.1 0-.1-.1-.2-.2l-.3-.3c.2.2.5.4.7.6 0 .1-.1 0-.1 0zm4.9-32.1l-25.7-79.1-22.8-70.1h96.9L293 402.4zm5.3 31.8l-.2.2-.1.1-.2.2c.3-.2.5-.4.7-.6 0-.1-.1 0-.2.1zm146.5-126.1l-110.6 80.3 98.7-126.5 13.4 41.3c.6 1.9 0 3.8-1.5 4.9z" />
    </svg>
  );
}

export default GitlabIcon;
