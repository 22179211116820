const allTags = {
  type: {
    internship: "Internship",
    project: "Project",
    extracurricular: "Extracurricular",
    academic: "Academic",
  },
  language: {
    cpp: "C++",
    csharp: "C#",
    html: "HTML",
    css: "CSS",
    scss: "Sass",
    c: "C",
    java: "Java",
    python: "Python",
    ruby: "Ruby",
    typescript: "TypeScript",
    javascript: "JavaScript",
    sql: "SQL",
    bash: "Bash",
    matlab: "MATLAB",
    go: "Go",
  },
  library: {
    rails: "Rails",
    dot_net: ".NET",
    wpf: "WPF",
    react: "React",
    angular: "Angular",
    rspec: "RSpec",
    serverless: "Serverless",
    opencv: "OpenCV",
    javafx: "JavaFX",
    jmock: "JMock",
    junit: "JUnit",
    ros2: "ROS 2",
    bootstrap: "Bootstrap",
    tkinter: "Tkinter",
    pygame: "Pygame",
    express: "Express",
    ionic: "Ionic",
  },
  tool: {
    packer: "Hashicorp Packer",
    aws: "AWS",
    postgresql: "PostgreSQL",
    git: "Git",
    docker: "Docker",
    jenkins: "Jenkins",
    google_cloud: "Google Cloud",
    heroku: "Heroku",
    jira: "Jira",
    azure: "Azure",
    elasticsearch: "ElasticSearch",
  },
} as const;

export default allTags;
