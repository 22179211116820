import CommonLink from "../../components/common/CommonLink";
import { DescriptionProps } from "../../models/item";
import ItemIdentifier from "../item_ids";

export default function HuntConsole({ itemUrlResolver }: DescriptionProps): JSX.Element {
  const rhmLink = (
    <CommonLink href={itemUrlResolver(ItemIdentifier.ReindeerHuntManager)}>Reindeer Hunt Manager</CommonLink>
  );

  return (
    <>
      <p>
        Developed as a successor to the {rhmLink}, the Hunt Console application is a general-purpose round-robin
        tournament manager. As with the {rhmLink}, it was designed with my high school’s annual Reindeer Hunt
        competition in mind, but had several improvements over the previous application, including:
      </p>
      <ul>
        <li>Ability for multiple users to collaborate on managing the competition</li>
        <li>Available on any device with a compatible web browser (including mobile devices)</li>
        <li>No user installation required</li>
        <li>Flexibility</li>
        <li>More features</li>
        <li>Better user experience</li>
      </ul>
      <p>
        The Hunt Console application was implemented with a service-style architecture; it is separated into a React
        frontend and a Rails HTTP API backend, with data stored on a PostgreSQL database. The first feature complete
        version of the application was developed in three months on my own initiative, but development has continued.
      </p>
    </>
  );
}
