enum ItemIdentifier {
  HuntConsole = "hunt-console",
  ReindeerHuntManager = "reindeer-hunt-manager",
  TrendMicro = "trend-micro",
  Watorace = "watorace",
  Ario = "ario",
  FRC2706 = "frc_2706",
  SmartMirror = "smart-mirror",
  Nymi = "nymi",
  BattleshipGalactica = "battleship-galactica",
  MultiplayerArcade = "multiplayer-arcade",
  ApeOverMath = "ape-over-math",
}

export default ItemIdentifier;
