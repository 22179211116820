import { ImageDescriptor } from "./image";
import { NonTypeTag, Tag, TypeTag } from "./tag";

type ItemLink = {
  url: string;
  label: React.ReactNode;
};

type DescriptionProps = {
  itemUrlResolver: (itemId: Item["id"]) => string;
};

export type DateRange = { from: Date; to: Date | null; formatter: (date: Date) => string };

class Item {
  /**
   * An array of images related to the item.
   * Each entry in the toplevel array is an array of alternative formats for
   * the same image. The entries should be in order of priority, with the last one
   * being the fallback (usually png format since it's most supported).
   * The first image is considered the main display image.
   */
  public images: ImageDescriptor[] | undefined;
  public description: React.ReactNode | ((props: DescriptionProps) => React.ReactNode) | undefined;
  public links: ItemLink[] = [];
  /** The date range over which the item is relevant.
   * If an array of ranges, then it should be sorted by end date, descending (most recent end date first).
   * A null value for `to` indicates that the item is still presently relevant.
   */
  public dateRange: DateRange | DateRange[] | undefined;

  private _typeTags: Set<TypeTag> = new Set();
  private _otherTags: Set<NonTypeTag> = new Set();
  /** Priority tags, which will be displayed in the order in which they are given. */
  private _featuredTags: Tag[] = [];

  constructor(public readonly name: string, public readonly id: string, public summary: string) {}

  public latestDateRange(): DateRange | undefined {
    if (Array.isArray(this.dateRange)) {
      return this.dateRange[0];
    }
    return this.dateRange;
  }

  public addTags(...tags: NonTypeTag[]): void {
    tags.forEach(this._otherTags.add.bind(this._otherTags));
  }

  public addTypes(...types: TypeTag[]): void {
    types.forEach(this._typeTags.add.bind(this._typeTags));
    // All type tags should be featured at the beginning.
    this._featuredTags.unshift(...types);
  }

  /**
   * Adds featured tags, which will be displayed in the order given.
   * Featured tags will also appear as regular tags on the item.
   */
  public addFeaturedTags(...featuredTags: NonTypeTag[]): void {
    this.addTags(...featuredTags);
    this._featuredTags = [...this._featuredTags, ...featuredTags];
  }

  public tap(func: (item: Item) => void): Item {
    func(this);
    return this;
  }

  public get allTags(): Tag[] {
    return [...Array.from(this._typeTags), ...Array.from(this._otherTags)];
  }

  public get types(): ReadonlySet<TypeTag> {
    return this._typeTags;
  }

  public get tags(): ReadonlySet<NonTypeTag> {
    return this._otherTags;
  }

  public hasTag(tag: NonTypeTag): boolean {
    return this.tags.has(tag);
  }

  public isOfType(type: TypeTag): boolean {
    return this.types.has(type);
  }

  public get featuredTags(): readonly Tag[] {
    return this._featuredTags;
  }
}

export default Item;
export type { ItemLink, DescriptionProps };
