export default function Nymi(): JSX.Element {
  return (
    <p>
      My first experiences in software development were with Toronto based Nymi, producers of the Nymi Band
      authentication wristband. My role as part of the quality assurance team was to test the product every weekend so
      that tickets created in Jira would be ready for triaging and prioritization in the next sprint the following week.
      I was introduced to Android debugging tools like ADB, running scripts from the Windows command prompt and I even
      had the opportunity to play with the SDK by creating my own Python scripts. The excitement of learning all these
      new things as well as the difficulty that came with it are a large part of the reason why I chose to continue with
      software.
    </p>
  );
}
