import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Main from "./containers/main/Main";

function App(): JSX.Element {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route>
            <Main />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
