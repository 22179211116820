enum SortProperty {
  Name = "Name",
  EndDate = "End Date",
  StartDate = "Start Date",
  Featured = "Featured",
}

enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export { SortProperty, SortDirection };
