import { Row } from "react-bootstrap";
import styles from "./FilterWrapper.module.scss";

export type FilterWrapperProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

export default function FilterWrapper(props: FilterWrapperProps): JSX.Element {
  return (
    <div className={`py-3 ${styles.filter_wrapper}`}>
      <Row className="justify-content-start">
        <h1>{props.title}</h1>
      </Row>
      <Row className="justify-content-start">{props.children}</Row>
    </div>
  );
}
