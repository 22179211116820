import { Col, Container, Row } from "react-bootstrap";
import { Envelope, FileEarmarkText, Github, Linkedin } from "react-bootstrap-icons";
import { EMAIL_URL, GITHUB_PROFILE_URL, GITLAB_PROFILE_URL, LINKEDIN_PROFILE_URL, RESUME_URL } from "../../constants";
import CommonLink from "../common/CommonLink";
import GitlabIcon from "../GitlabIcon";

export default function Title(): JSX.Element {
  return (
    <div className="p-3 bg-light rounded-3 mb-3">
      <Container fluid className="text-center">
        <h1 className="display-5 fw-bold">Kyle Anderson</h1>
        <Row className="justify-content-center">
          <Col>
            <CommonLink href={GITHUB_PROFILE_URL}>
              <Github width="50px" height="50px" className="mx-2" />
            </CommonLink>
            <CommonLink href={LINKEDIN_PROFILE_URL}>
              <Linkedin width="50px" height="50px" className="mx-2" />
            </CommonLink>
            <CommonLink href={GITLAB_PROFILE_URL}>
              <GitlabIcon width="75px" height="75px" className="mx-0" />
            </CommonLink>
            <CommonLink href={EMAIL_URL}>
              <Envelope width="50px" height="50px" className="mx-2" />
            </CommonLink>
            <CommonLink href={RESUME_URL}>
              <FileEarmarkText width="50px" height="50px" className="mx-2" />
            </CommonLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
