export default function LCBONext(): JSX.Element {
  return (
    <>
      <p>
        As one of the two full stack developers on the team, working at the lab meant having direct responsibility for
        the products and their interfaces.
      </p>
      <p>
        Entering the work term in high gear, I made effective changes to help the lab deliver the final stages of two
        business-facing products within the first week with the team. Enhancements included switching a search endpoint
        to ElasticSearch, improving search speeds fivefold and optimizing the development environment with Docker
        containers to replicate all the required resources. It also included completing major features across diverse
        stacks made up of HTML, CSS, JavaScript and TypeScript while employing libraries and frameworks like React,
        Express and Angular.
      </p>
      <p>
        I safely deployed a fix to a live outage caused by a third party API issue with minimal production downtime.
        Aside from this unscheduled deployment, I also handled managing and deploying our projects to Azure services and
        additionally took on internal requests from LCBO teams, effectively managing these extra tasks alongside our
        main objectives.
      </p>
    </>
  );
}
