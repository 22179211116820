import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { ImageDescriptor } from "../../models/image";
import styles from "./ImageCarousel.module.scss";
import ImageSelector, { ImageSelectorProps } from "./ImageSelector";

type CarouselItemProps = Readonly<ImageDescriptor> & { readonly caption?: React.ReactNode };

export type ImageCarouselProps = {
  images: CarouselItemProps[];
};

export default function ImageCarousel(props: ImageCarouselProps): JSX.Element {
  const [index, setIndex] = useState<number>(0);

  const imageElement: Exclude<ImageSelectorProps["imageElement"], undefined> = (src, altText) => (
    <img src={src} alt={altText} className={`d-block w-100 h-100 ${styles.carousel_image}`} />
  );

  return (
    <Carousel className={styles.carousel_slide} activeIndex={index} onSelect={setIndex}>
      {props.images.map(({ caption, ...descriptor }, i) => {
        return (
          <Carousel.Item key={i} className={styles.image_carousel_item}>
            <ImageSelector imageElement={imageElement} availableFormats={descriptor} />
            {caption && <Carousel.Caption>{caption}</Carousel.Caption>}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
