import React from "react";
import Title from "../../components/title";
import Items from "../items";

export default function Main(): JSX.Element {
  return (
    <div>
      <Title />
      <Items />
    </div>
  );
}
