export default function Snowflake(): JSX.Element {
  return (
    <>
      <p>
        Snowflake provides a feature-rich distributed relational database solution for its users. One of the major
        features in this database is the ability for users to specify arbitrary queries to run on a schedule in the form
        of tasks. Tasks can also be set to run on completion of one or multiple parent tasks, allowing the user to form
        workflows. One problem that users faced however was that failed tasks could only be rerun by restarting the
        entire graph of tasks from the beginning. This was frustrating for users, so it was my project to make it
        possible for task graphs to be restarted from the points of failure.
      </p>
      <p>
        I worked independently to draft proposals, create a project plan, and initiate design reviews with the team,
        landing approval for the chosen design and proceeding into the implementation and delivery stages of the
        project. These latter stages also involved cross-team collaboration to publish documentation and coordinate a
        corresponding graphical user interface for the new feature. Lastly, I saw the project through to its deployment
        and monitoring.
      </p>
      <p>
        Though my time at Snowflake was mainly focused on this single feature, the large scale changes and coordination
        it required in its implementation combined with the impactful benefit it would have for users made it a very
        interesting and challenging task.
      </p>
    </>
  );
}
