import { DateRange as DateRangeData } from "../../models/item";

export type DateRangeProps = {
  range: Readonly<DateRangeData | undefined>;
};

export default function DateRange({ range }: DateRangeProps): JSX.Element {
  if (typeof range === "undefined") {
    return <></>;
  }

  const fromString = range.formatter(range.from);
  const toString = range.to === null ? "Present" : range.formatter(range.to);

  return (
    <p>
      {fromString} - {toString}
    </p>
  );
}
