export default function ReindeerHuntManager(): JSX.Element {
  return (
    <>
      <p>
        Each year at my high school, a competition between ~1700 students was run by the student council. Students were
        to be paired up and hunt each other over several rounds until few remained. All of the management for this hunt
        was done manually however, and with a great margin of error. This is why I took the initiative to create a
        C#.NET Windows application to manage the tasks automatically.
      </p>
      <p>
        In its first year, it eliminated errors such as missing students, too many students paired together and lost
        match records. It enabled a single person to manage the entire competition, and in drastically less time.
      </p>
    </>
  );
}
