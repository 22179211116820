import { Row } from "react-bootstrap";
import Item from "../../models/item";
import ItemTags from "./ItemTags";

export type FeaturedItemTagProps = {
  item: Readonly<Item>;
};

/**
 * Renders a list of the featured item tags for the given item.
 */
export default function FeaturedItemTags({ item }: FeaturedItemTagProps): JSX.Element {
  return (
    <Row className="justify-content-center">
      <ItemTags tags={item.featuredTags} />
    </Row>
  );
}
