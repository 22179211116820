import allItems from "../data/items";
import Item from "../models/item";

class ItemNotFoundError extends Error {
  public readonly itemId: Item["id"];

  constructor(itemId: ItemNotFoundError["itemId"]) {
    super(`Failed to fetch item with ID ${itemId}`);
    this.itemId = itemId;
  }
}

async function fetchItems(): Promise<Readonly<Item>[]> {
  return allItems;
}

async function fetchItem(itemId: Item["id"]): Promise<Readonly<Item>> {
  const item = allItems.find((item) => item.id === itemId);
  if (!item) {
    throw new ItemNotFoundError(itemId);
  }
  return item;
}

export { fetchItems, fetchItem, ItemNotFoundError };
