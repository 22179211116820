import Item, { DateRange } from "../../models/item";
import { SortDirection, SortProperty } from "../../models/item_sort";

function sortItems(
  items: readonly Readonly<Item>[] | undefined,
  sortProperty: SortProperty,
  sortDirection: SortDirection,
): Readonly<Item>[] | undefined {
  const multiplier = sortDirection === SortDirection.Ascending ? 1 : -1;
  if (items === undefined) {
    return undefined;
  }
  function dateRangeCompare(
    date1: DateRange["from" | "to"] | undefined | null,
    date2: DateRange["from" | "to"] | undefined | null,
  ): number {
    if (!date1 || !date2) {
      function assignComparisonKey(value: unknown): number {
        let comparisonKey: number;
        /* Smaller value sorts first. */
        if (value === undefined) {
          comparisonKey = 2;
        } else if (value === null) {
          comparisonKey = 1;
        } else {
          comparisonKey = 0;
        }
        return comparisonKey;
      }
      let comparisonValue = assignComparisonKey(date1) - assignComparisonKey(date2);
      if (!(date1 === undefined || date2 === undefined)) {
        /* We only obey the sort direction if neither values are undefined. Otherwise, we want the undefined
        value to go last. */
        comparisonValue *= multiplier;
      }
      return comparisonValue;
    }
    return (date1 < date2 ? -1 : 1) * multiplier;
  }

  const results = [...items];
  switch (sortProperty) {
    case SortProperty.Featured:
      if (sortDirection === SortDirection.Descending) {
        // This is a copy of the array so this does not mutate any state or props.
        results.reverse();
      }
      return results;
    case SortProperty.Name:
      return results.sort((item1, item2) => (item1.name < item2.name ? -1 : 1) * multiplier);
    case SortProperty.StartDate:
      return results.sort((item1, item2) =>
        dateRangeCompare(item1.latestDateRange()?.from, item2.latestDateRange()?.from),
      );
    case SortProperty.EndDate:
      return results.sort((item1, item2) => dateRangeCompare(item1.latestDateRange()?.to, item2.latestDateRange()?.to));
  }
}

export default sortItems;
