import { ImageDescriptor } from "../../models/image";
import * as autoSearch from "./auto_search";
import * as betterSearchBar from "./better_search_bar";
import * as historySearch from "./history_search";
import * as itemDetailsUiRedesign from "./item_details_ui_redesign";
import * as logo from "./logo";
import * as newItemCard from "./new_item_card";
import * as pendingStatus from "./pending_status";
import * as popularitySearch from "./popularity_search";
import * as removeAll from "./remove_all";
import * as scrollingLoader from "./scrolling_loader";

const images: ImageDescriptor[] = [
  logo,
  autoSearch,
  betterSearchBar,
  historySearch,
  itemDetailsUiRedesign,
  newItemCard,
  pendingStatus,
  popularitySearch,
  removeAll,
  scrollingLoader,
];
export default images;
