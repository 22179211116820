import { useCallback } from "react";
import { Col } from "react-bootstrap";

export type TagFilterProps<T> = {
  isSelected: boolean;
  tagLabel: T;
  onSelectionChange: (tag: T, isNowSelected: boolean) => void;
};

export default function TagFilter<T>({ isSelected, tagLabel, onSelectionChange }: TagFilterProps<T>): JSX.Element {
  const handleSelectionChange = useCallback(() => {
    onSelectionChange(tagLabel, !isSelected);
  }, [onSelectionChange, isSelected, tagLabel]);

  return (
    <Col md={12} xs={6} className="text-start">
      <label>
        <input className="mx-1" type="checkbox" checked={isSelected} onChange={handleSelectionChange} />
        {tagLabel}
      </label>
    </Col>
  );
}
