import { TupleN, Unionize } from "./lib/helper_types";
import Item from "./models/item";

type Identifiable =
  | {
      readonly id: string;
    }
  | string;

function extractId(identifiable: Identifiable | string): string {
  return typeof identifiable === "string" ? identifiable : identifiable.id;
}

function generatePath<T extends Identifiable>(
  pathPrefix: string,
  pathParameter: string,
  identifiable?: T | string,
): string {
  return `${pathPrefix}/${identifiable === undefined ? pathParameter : extractId(identifiable)}`;
}

function pathFor<T extends Identifiable>(
  pathPrefix: string,
  pathParameter: string,
): (identifiable?: T | string) => string;
function pathFor<T extends Identifiable[]>(
  ...pathArguments: TupleN<[string, string], T["length"]>
): (...identifiables: Unionize<T, string | undefined>) => string;
function pathFor(...pathArguments: unknown[]): (...identifiables: (string | undefined | Identifiable)[]) => string {
  if (Array.isArray(pathArguments[0])) {
    return function (...identifiables): string {
      return (pathArguments as [string, string][])
        .map(([pathPrefix, pathParameter], i) => generatePath(pathPrefix, pathParameter, identifiables[i]))
        .join("/");
    };
  } else {
    return pathFor(pathArguments as [string, string]);
  }
}

const ITEM_ID_PARAM = "itemId" as const;
const itemsPath = "/" as const;
const itemPath = pathFor<Readonly<Item>>("/items", `:${ITEM_ID_PARAM}`);

export { ITEM_ID_PARAM, itemPath, itemsPath };
