import React, { Fragment, useCallback } from "react";
import FilterWrapper from "./FilterWrapper";

export type TagProps<T> = {
  /** A set containing all tags which are selected. */
  selectedTags: ReadonlySet<T>;
  /**
   * A function to be called when the argument tag is selected or deselected.
   * The first entry in each pair is the tag whose value has changed, the second
   * part of the pair is the new state for that tag; true if selected, false otherwise.
   */
  onSelectionChange: (newTagValues: [T, boolean][]) => void;
  children: (props: { isSelected: boolean; onSelectionChange: (tagLabel: T) => void; tagLabel: T }) => React.ReactNode;
  tagsToDisplay: T[];
  title: React.ReactNode;
};

export default function Tags<T>({
  selectedTags,
  onSelectionChange,
  children,
  title,
  tagsToDisplay,
}: TagProps<T>): JSX.Element {
  const isSelected = useCallback(
    function isSelected(tag: T): boolean {
      return selectedTags.has(tag);
    },
    [selectedTags],
  );

  const handleSelectionChange = useCallback(
    function handleSelectionChange(tagLabel: T) {
      onSelectionChange([[tagLabel, !isSelected(tagLabel)]]);
    },
    [onSelectionChange, isSelected],
  );

  return (
    <FilterWrapper title={title}>
      {tagsToDisplay.map((tagLabel, i) => {
        return (
          <Fragment key={i}>
            {children({ isSelected: isSelected(tagLabel), onSelectionChange: handleSelectionChange, tagLabel })}
          </Fragment>
        );
      })}
    </FilterWrapper>
  );
}
