import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Item from "../../../models/item";
import { fetchItem, ItemNotFoundError } from "../../../repositories/item_repository";
import { ITEM_ID_PARAM } from "../../../routes";
import ItemDisplay, { ItemDisplayProps } from "./ItemDisplay";

export type SingleItemProps = {
  /** The item to be displayed. If only a string is given, then it is assumed to the be item's identifier,
   * and the rest of the data will be fetched.
   */
  itemToDisplay: Readonly<Item> | null | undefined;
  setDisplayItem: (item: Readonly<Item> | null) => void;
} & Pick<ItemDisplayProps, "showNextItem" | "showPreviousItem">;

type ItemPathProps = {
  [ITEM_ID_PARAM]: string;
};

export default function SingleItem({
  itemToDisplay,
  setDisplayItem,
  ...itemDisplayProps
}: SingleItemProps): JSX.Element {
  const { [ITEM_ID_PARAM]: itemId } = useParams<ItemPathProps>();

  useEffect(() => {
    if (!itemToDisplay) {
      fetchItem(itemId)
        .then((item) => {
          setDisplayItem(item);
        })
        .catch((e) => {
          if (e instanceof ItemNotFoundError) {
            console.error(e.message);
          }
        });
    }
  }, [itemId, itemToDisplay, setDisplayItem]);

  const hideItem = useCallback(
    function hideItem() {
      setDisplayItem(null);
    },
    [setDisplayItem],
  );
  return (
    <ItemDisplay
      item={itemToDisplay instanceof Item ? itemToDisplay : undefined}
      hideItem={hideItem}
      {...itemDisplayProps}
    />
  );
}
