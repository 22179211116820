import CommonLink from "../../components/common/CommonLink";

export default function TrendMicro(): JSX.Element {
  return (
    <>
      <h1>Product Development Fall 2021</h1>
      <p>
        For my second term with Trend Micro, I switched to a product development team responsible for components of the
        Deep Security Agent. The agent was a multi-repository endeavour with C++ and Lua in use, and it required
        knowledge of Linux and Windows systems. I was able to leverage my knowledge of Linux systems to add support for
        newer STATX library calls for more detailed file timestamps, as well as reduce database purging operation times
        from minutes to milliseconds in an SQLite3 database.
      </p>
      <p>
        I started a new <CommonLink href="https://www.serverless.com/">Serverless</CommonLink> AWS Lambda service which
        would act as an HTTP REST API for an automated configuration suggestion system, with the goal of improving the
        ease-of-use of the security product's configuration system, as well as to catch misconfigurations before they
        were exploited. This cloud-native service interfaced with DynamoDB to fetch recommendation data populated
        originally from an S3 bucket. Since this project was in its early stages, my role was not strictly bound to a
        prescribed task, and I took the initiative to add Dockerized development configurations for the new
        repositories, add thorough automated HTTP and integration tests, complete with a local DynamoDB instance, and
        participated in threat modelling discussions and security hardening.
      </p>
      <p>
        I then continued with my initiatives to Dockerize repositories and brought devcontainers to development and
        testing environments aside from the new project, significantly reducing the overhead of beginning development in
        a new repository and keeping the developer environments consistent and reproducible. I presented this project at
        a company-wide hackathon, and earned third place.
      </p>
      <h1>Platform Team Spring 2020</h1>
      <p>
        In my first term with the company, I joined Trend Micro as a member of the platform team, responsible for
        developing and maintaining various internal tools. My day-to-day tasks varied from creating new Jenkins
        pipelines in groovy to deploying new applications to AWS.
      </p>
      <p>
        I also spent a lot of time creating and updating Hashicorp Packer configurations for provisioning AWS machine
        images systematically. These tasks gave me a lot of exposure working with different operating systems, including
        Red Hat Enterprise Linux 7-8, Windows Server, CentOS, CloudLinux 7-8, Ubuntu, Debian, Amazon Linux, Suse and
        Oracle Linux.
      </p>
      <p>
        In my last month with the team, I worked very closely with another intern on developing a Slack bot which
        streamlined the process of creating Jira issues from Slack conversations. The application was architected using
        a completely serverless architecture, leveraging AWS lambda, SNS, S3 and the secrets manager to implement all
        the features. Additionally, to work around performance problems with the Jira server, I implemented a caching
        mechanism using AWS S3 which promptly sped up loading times from over five seconds to under two.
      </p>
      <p>
        Trend Micro was a great opportunity for me to expand my knowledge in other areas of software, which is why I
        completed the{" "}
        <CommonLink href="https://owasp.org/www-project-secure-coding-dojo/">
          OWASP secure coding dojo black belt challenge
        </CommonLink>
        . I also participated in an internal simulation event where employees were split into two groups - attackers and
        defenders - and would then attempt to either infiltrate or secure an insecure application.
      </p>
    </>
  );
}
