export default function Ario(): JSX.Element {
  return (
    <>
      <p>
        As a developer at Ario, I worked on a full stack capacity for the company’s online lending platform. I overcame
        challenges familiarizing myself with the platform’s inner workings, with many of the financial aspects as well
        as expanding my knowledge in Ruby on Rails, Angular and other web technologies.
      </p>
      <p>
        Specifically, I started off with the company in test automation, creating automatable load test scripts in
        Python and JMeter, as well as helping the company improve the quality of the platform. My diligence with testing
        earned me the nickname “Kyle Storm”. My role evolved into that of a full stack developer, adding features and
        fixing bugs in both the company’s frontend Angular components and backend Rails stack.
      </p>
    </>
  );
}
