import React from "react";
import Item from "../../../models/item";
import CommonLink from "../../common/CommonLink";

export type ItemLinkProps = {
  item: Readonly<Item>;
};

export default function ItemLinks({ item }: ItemLinkProps): JSX.Element {
  return (
    <>
      {item.links.map(({ url, label }, i) => {
        return (
          <span className="mx-2" key={i}>
            <CommonLink href={url}>{label}</CommonLink>
          </span>
        );
      })}
    </>
  );
}
