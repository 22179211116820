import CommonLink from "../../components/common/CommonLink";

const MULTIPLAYER_ARCADE_GITHUB_BASE_URL = "https://github.com/KyleRAnderson/Multiplayer-Arcade";

export default function MultiplayerArcade(): JSX.Element {
  return (
    <>
      <p>
        Together with a peer from my high school, we re-created a version of the classic game of pong, with several
        interesting tweaks, including:
      </p>
      <ul>
        <li>Local multiplayer, with two players both operating the controls on the same keyboard.</li>
        <li>
          Local single player, where a player competes against bots of adjustable difficulties, including one which
          would guess the position of the ball and travel there each time.
        </li>
        <li>Online peer-to-peer multiplayer with two players on the same local network.</li>
      </ul>
      <p>
        This game was also a great learning opportunity for designing a good project structure. The entire game logic is
        de-coupled from the UI, a design decision made early on to ensure that the logic was clear, testable and would
        be well suited to creating a network-based game.{" "}
        <CommonLink href={`${MULTIPLAYER_ARCADE_GITHUB_BASE_URL}/projects?query=is%3Aclosed`}>
          Project management methods
        </CommonLink>{" "}
        were used extensively, and I really enjoyed working closely with another developer on this fun project.
      </p>
    </>
  );
}

export { MULTIPLAYER_ARCADE_GITHUB_BASE_URL };
