import Item, { DateRange as DateRangeData } from "../../models/item";
import DateRange from "./DateRange";

type DateRangesProps = { ranges: Item["dateRange"] };

export default function DateRanges({ ranges }: DateRangesProps): JSX.Element {
  if (typeof ranges === "undefined") {
    return <></>;
  }
  const allRanges: DateRangeData[] = Array.isArray(ranges) ? ranges : [ranges];
  return (
    <>
      {allRanges.map((range, i) => (
        <DateRange key={i} range={range} />
      ))}
    </>
  );
}
