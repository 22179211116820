/* You might be wondering: why do we have a repository for something that's hard coded.
The reason: flexibility if things are ever changed to something that isn't hard-coded.
One day we may decide to store this information on a server and fetch it instead of having
it hard-coded here. Keeping this structure, when that day comes, we only need to change
these methods to a couple of HTTP requests and the change is done. */

import allTags from "../data/tags";
import { AllTags } from "../models/tag";

async function fetchTags(): Promise<AllTags> {
  return allTags;
}

export { fetchTags };
